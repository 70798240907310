<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Leads</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>

            <div class="float-end">
              <button-widget cor="azul" href="/leads/create" tamanho="M">
                Adicionar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table table-responsive table-striped">
          <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">


            <td>
              <div class="dropdown">
                <a class="btn btn-primary btn-sm dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                   aria-expanded="false">
                  Ações
                </a>

                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="'/leads/edit/'+row.id">
                      <i class="bi bi-pencil-square"></i>
                      Editar
                    </router-link>
                  </li>
                  <li>
                        <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                           <i class="bi bi-trash2-fill"></i>
                          Apagar
                        </span>
                  </li>

                </ul>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-12">
                  <strong>Nome: </strong>
                  {{ row.nome }}
                </div>
                <div class="col-12">
                  <strong>Telefone: </strong>
                  {{ row.telefone }}
                </div>
                <div class="col-12">
                  <strong>Atendimento: </strong>
                  {{row.atendimento}}
                </div>
                <div class="col-12">
                  <strong>Data de Cadastro: </strong>
                  {{ new DateUtil().format(row.created_at) }}
                </div>
              </div>
            </td>
              <td>
              <div class="row">
                <div class="col-12">
                  <strong>CPF OU CNPJ: </strong>
                  {{ row.cpf == '' || row.cpf == undefined || row.cpf == null ? row.cnpj:row.cpf }}
                </div>
                <div class="col-12">
                  <strong>E-mail: </strong>
                  {{row.email}}
                </div>
                <div class="col-12">
                  <strong>Atuação Financeira</strong>
                  {{row.atuacao_financeira=='sim'?'Sim':''}}
                  {{row.atuacao_financeira=='nao'?'Não':''}}
                </div>
                <div class="col-12">
                  <strong>Status: </strong>
                  {{row.status}}
                </div>
              </div>


            </td>



          </tr>
          <tr v-if="rows==null ">
            <td colspan="2" >
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows==''">
            <td colspan="2" class="text-center"> Não há dados</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import LeadService from "@/services/lead.service";
import Helpers from "@/services/Helpers";
import DateUtil from "../../utils/date.util";


export default {
  name: "IndexLeads",
  computed: {
    DateUtil() {
      return DateUtil
    }
  },
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null
    }
  },
  methods: {
    async list() {

      let leadsService = new LeadService();
      let dataRow = await leadsService.list(this.search);
      let helpers = new Helpers();
      console.log(dataRow.data.data);
      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;
        return;

      }

      if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      }
      this.rows = '';


    },
    async deleteRow(id) {
      let leadsService = new LeadService();
      let dataRow = await leadsService.delete(id);
      console.log(dataRow)
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "../../../node_modules/toastr/build/toastr.css";
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";
</style>
